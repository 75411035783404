import React from "react"
import Layout from "../components/flayout"
import ContentPage from "../components/contentPage"
import { graphql } from "gatsby";
import ReactHtmlParser from 'react-html-parser';
import DesktopDownloadLink from "../components/desktopDownloadLink";

const Page = ({data}) => {
    console.log(data);
    return (
        <Layout darkHeader={true} seoFields={seoFields}>
            <ContentPage
                title={'Version History'}
            >
                <DesktopDownloadLink/>
                {
                    ReactHtmlParser(data.wordpressPage.content)
                }
            </ContentPage>
        </Layout>
    )
}

const seoFields = {
  description: "MProfit Version History",
  // lang,
  // meta,
  // keywords,
  title: "Version History" 
}

export const query = graphql`
query versionHistoryQuery{
    wordpressPage(slug:{eq:"version-history"}) {
        wordpress_id
        content
    }
}
`

export default Page
