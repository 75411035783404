import React from "react"
import styles from "./index.module.css";

const DesktopDownloadLink = () => {
    return (
        <div className={styles.container}>
            <a href="https://files.mprofit.in/import/MProfitDesktopSetup.exe" target="_blank" className={styles.link}>Click here</a> to download the latest version of MProfit Desktop
        </div>
    )
}

export default DesktopDownloadLink
